<template>
  <section class="login">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth bg-gradient-primary">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-5 col-md-8 col-sm-12 mx-auto">
              <div class="auth-form-light text-left p-4 p-md-5 rounded-lg">
                <img
                  src="@/assets/images/sangthai_logo.png"
                  class="rounded d-block img-fluid"
                  alt="logo"
                  width="150"/>
                <h3 class="font-bold color-sangthai">
                  SANGTHAI MANAGEMENT
                </h3>
                <h6 class="font-weight-light text-black">
                  ใส่ชื่อผู้ใช้งานและรหัสผ่านเพื่อเข้าสู่ระบบ
                </h6>
                <form class="mt-4">
                  <div class="form-group">
                    <input
                      v-model="username"
                      type="text"
                      class="form-control rounded-lg"
                      id="username"
                      autocomplete="nickname"
                      placeholder="ชื่อผู้ใช้งาน"/>
                  </div>
                  <div class="form-group">
                    <input
                      v-model="password"
                      type="password"
                      class="form-control rounded-lg"
                      id="password"
                      autocomplete="new-password"
                      placeholder="รหัสผ่าน"/>
                  </div>
                  <div class="mt-4">
                    <button
                      class="btn btn-block btn-primary shadow btn-lg rounded-lg auth-form-btn text-sangthai"
                      @click.prevent="login()">
                      เข้าสู่ระบบ
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="loading" />
  </section>
</template>

<script>
import Loading from '@/components/widgets/Loading.vue'
export default {
  name: "login",
  data() {
    return {
      loading: false,
      username: "",
      password: "",
    }
  },
  components: {
    Loading
  },
  mounted(){
    const token = localStorage.Sangthai_access_token
    if (token && token !== 'null') {
      this.$router.push('/profile')
    }
  },
  methods: {
    async login() {
      let qry = this.$route.query.redirectTo
      if (this.username && this.password) {
        this.loading = true
        const body = {
          username: this.username,
          password: this.password
        }
        this.$store.dispatch('Auth/login', body, { root: true }).then(response => {
          if(response.status === 200 && response.data.access_token){
            this.loading = false
            this.$router.push(qry && qry !== '/' ? qry : '/profile')
          } else if (response.status === 400) {
            this.loading = false
            this.$swal({
              width: "29rem",
              padding: '1.825em',
              html: '<div class="d-flex flex-row align-items-center">' +
                '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
                '<div class="d-flex flex-column">'+
                '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
                `<span class="text-base text-left text-gray-500">ชื่อผู้ใช้งานหรือรหัสผ่านผิด</span>`+
                '</div>'+
              '</div>',
              allowOutsideClick: false,
              focusConfirm: false,
              customClass: {
                popup: 'swal2-custom-rounded',
                closeButton: 'text-3xl swal2-custom-close',
                actions: 'justify-content-end',
                contant: 'd-flex flex-row justify-content-around px-2',
                title: 'd-none',
                confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
              },
              showCancelButton: false,
              confirmButtonText: "ตกลง",
              cancelButtonText: "ยกเลิก",
              showCloseButton: true,
            })
          } else {
            this.loading = false
            this.$swal({
              width: "29rem",
              padding: '1.825em',
              html: '<div class="d-flex flex-row align-items-center">' +
                '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
                '<div class="d-flex flex-column">'+
                '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
                `<span class="text-base text-left text-gray-500">${response.response.error_description}</span>`+
                '</div>'+
              '</div>',
              allowOutsideClick: false,
              focusConfirm: false,
              customClass: {
                popup: 'swal2-custom-rounded',
                closeButton: 'text-3xl swal2-custom-close',
                actions: 'justify-content-end',
                contant: 'd-flex flex-row justify-content-around px-2',
                title: 'd-none',
                confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
              },
              showCancelButton: false,
              confirmButtonText: "ตกลง",
              cancelButtonText: "ยกเลิก",
              showCloseButton: true,
            })
          }
        }).catch((error) => {
          this.loading = false
          this.$swal({
              width: "29rem",
              padding: '1.825em',
              html: '<div class="d-flex flex-row align-items-center">' +
                '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
                '<div class="d-flex flex-column">'+
                '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
                `<span class="text-base text-left text-gray-500">`+error+`</span>`+
                '</div>'+
              '</div>',
              allowOutsideClick: false,
              focusConfirm: false,
              customClass: {
                popup: 'swal2-custom-rounded',
                closeButton: 'text-3xl swal2-custom-close',
                actions: 'justify-content-end',
                contant: 'd-flex flex-row justify-content-around px-2',
                title: 'd-none',
                confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
              },
              showCancelButton: false,
              confirmButtonText: "ตกลง",
              cancelButtonText: "ยกเลิก",
              showCloseButton: true,
            })
        })
      }
    }
  }
}
</script>
